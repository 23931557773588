<template>
  <div class="contr-map">

    <div class="map" id="map"></div>

    <div class="contr-button">
      <img class="location-type" @click="changeMapType" :src=imgMapType alt=""/>
      <img class="location-type" style="{margin-top: 20px}" @click="changeLocation" :src=imgLocation alt=""/>
    </div>

    <div class="contr-status">
      <img class="status-icon" :src=userInfo.imgBattery alt=""/>
      <img class="status-icon" src="../assets/main_signal_4.png" alt=""/>
      <img class="status-icon" src="../assets/main_satellite_2.png" alt=""/>
    </div>
  </div>
</template>

<script>
import BMap from 'BMap'
import iconNavigation from '@/assets/main_location_pop_navigation.png'
import iconPosition from '@/assets/main_location_position.png'
import iconTime from '@/assets/main_location_time.png'
import iconType from '@/assets/main_location_type.png'
import imgPhone from "@/assets/main_locationtype_phone.png";
import imgDevice from "@/assets/main_locationtype_device.png";
import imgMapTypeNormal from "@/assets/main_maptype_normal.png";
import imgMapTypeHybrid from "@/assets/main_maptype_satellite.png";
import {Toast} from "vant";
import {post} from "@/router/httpUtils";
import {
  app_NetworkError,
  app_State_1800, app_State_1801, app_State_1802, OrderSet_SendFailure,
  OrderSet_SendSuccess,
  State_0,
  State_1800,
  State_1801,
  State_1802,
  State_2200
} from "@/constants/contants";
import {str2Date, utc2localStr} from "@/util/timeUtil";

export default {
  name: "LocationMap",
  props: {
    center: {lng: 0, lat: 0},
    zoom: Number,
    userInfo: {},
  },
  watch: {
    center: {
      handler(data) {
        this.updateMap(data)
      },
      deep: true
    },
  },
  mounted() {
    this.createMap()
  },
  data() {
    return {
      map: null,
      infoOverlay: null,
      marker: null,
      imgLocation: imgPhone,
      imgMapType: imgMapTypeNormal
    }
  },
  methods: {
    changeMapType() {
      console.log('changeMapType')
      /* eslint-disable */
      if (this.imgMapType === imgMapTypeNormal) {
        this.imgMapType = imgMapTypeHybrid
        this.map.setMapType(BMAP_HYBRID_MAP)
      } else {
        this.imgMapType = imgMapTypeNormal
        this.map.setMapType(BMAP_NORMAL_MAP)
      }
      /* eslint-enable */
    },
    changeLocation() {

      if (this.imgLocation === imgDevice) {
        this.imgLocation = imgPhone
        this.watchLocation()
      } else {
        this.imgLocation = imgDevice
        this.phoneLocation()
      }
    },
    phoneLocation() {
      /* eslint-disable */
      Toast.loading({
        message: '定位中...',
        forbidClick: true
      })
      const that = this
      const geolocation = new BMap.Geolocation();
      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function (r) {
        Toast.clear()
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          that.map.removeOverlay(that.marker)
          that.marker = new BMap.Marker(r.point);
          that.map.addOverlay(that.marker);
          that.map.panTo(r.point);
        } else {
          alert('定位失败：' + this.getStatus());
        }
      });
      /* eslint-enable */
    },
    watchLocation() {
      if (this.marker) {
        this.map.removeOverlay(this.marker)
      }
      console.log(this.center)
      const point = new BMap.Point(this.center.lng, this.center.lat)
      this.marker = new BMap.Marker(point)
      this.map.addOverlay(this.marker);
      this.map.panTo(point);
    },
    sendCommand2Device() {
      console.log('sendCommand2Device')
      post('/api/Command/SendCommand', {
        "CmdCode": "0039",
        "DeviceId": localStorage.DeviceId,
        "DeviceModel": this.userInfo.DeviceModel,
        "Params": "",
        "Token": localStorage.Token,
        "UserId": localStorage.UserId
      }).then(res => {
        this.handleCommandResult(res)
      })
    },
    handleCommandResult(res) {
      const state = res.State
      if (res.Content != "NetworkError") {
        if (state == State_0) {
          Toast.success({
            message: OrderSet_SendSuccess,
            duration: 1000
          })
        } else if (state == State_1800) {
          Toast.fail({
            message: app_State_1800,
            duration: 3000
          })
        } else if (state == State_1801) {
          Toast.fail({
            message: app_State_1801,
            duration: 3000
          })
        } else if (state == State_1802) {
          Toast.fail({
            message: app_State_1802,
            duration: 3000
          })
        } else if (state == State_2200) {
          Toast.fail({
            message: '请先到孝心环设置界面设置主控号码',
            duration: 3000
          })
        } else {
          Toast.fail({
            message: OrderSet_SendFailure,
            duration: 3000
          })
        }
      } else {
        Toast.fail({
          // eslint-disable-next-line no-undef
          message: app_NetworkError,
          duration: 3000
        })
      }
    },
    createMap() {
      /* eslint-disable */
      console.log(this.center)
      // 创建Map实例
      const map = new BMap.Map("map");
      // 初始化地图,设置中心点坐标和地图级别
      const point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, this.zoom)

      //添加地图类型控件
      // map.addControl(new BMap.MapTypeControl({
      //   mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
      // }))

      // 设置地图显示的城市 此项是必须设置的
      map.setCurrentCity("北京")

      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)

      this.map = map
      /* eslint-enable */
    },
    sizeArea(radius) {
      this.map.removeOverlay(this.area)
      this.area.setRadius(radius)
      this.map.addOverlay(this.area)
    },
    moveArea() {
      const point = new BMap.Point(this.map.getCenter().lng, this.map.getCenter().lat)
      this.map.removeOverlay(this.area)
      this.area.setCenter(point)
      this.map.addOverlay(this.area)
    },
    updateMap(center) {
      /* eslint-disable */
      console.log(center)

      const point = new BMap.Point(center.lng, center.lat)
      this.map.setCenter(point)

      // const myIcon = new BMap.Icon("https://www.chitus.com/front/img/logo.80133e06.png", new BMap.Size(50, 50), {
      //   // 指定定位位置。
      //   // 当标注显示在地图上时，其所指向的地理位置距离图标左上
      //   // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
      //   // 图标中央下端的尖角位置。
      //   anchor: new BMap.Size(10, 25),
      //   // 设置图片偏移。
      //   // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
      //   // 需要指定大图的偏移位置，此做法与css sprites技术类似。
      //   // imageOffset: new BMap.Size(0, 0 - index * 25)   // 设置图片偏移
      // })
      // const marker = new BMap.Marker(point, {icon: myIcon});
      this.marker = new BMap.Marker(point)

      this.marker.addEventListener("click", () => {
        if (this.infoOverlay.isVisible()) {
          this.infoOverlay.hide()
        } else {
          this.infoOverlay.show()
        }
      })

      this.map.addOverlay(this.marker);
      this.map.disableDragging()

      const that = this

      // test start
      function ComplexCustomOverlay(point, text, tipText) {
        this._point = point;
        this._text = text;
        this._tipText = tipText;
      }

      ComplexCustomOverlay.prototype = new BMap.Overlay();
      ComplexCustomOverlay.prototype.initialize = function (map) {
        this._map = map;
        let div = this._div = document.createElement("div");
        div.style.position = "absolute";
        div.style.zIndex = BMap.Overlay.getZIndex(this._point.lat);
        div.style.background = "#fff";
        div.style.whiteSpace = "nowrap";
        // div.style.MozUserSelect = "none";
        div.style.fontSize = "14px";
        // div.style.textAlign = 'center';
        // div.className = 'point';
        div.style.border = '1px solid gray'
        div.style.borderRadius = '6px'
        div.style.paddingTop = '10px'
        div.style.paddingBottom = '10px'
        div.style.display = 'flex'
        div.style.flexDirection = 'column'

        let contrTop = document.createElement('div')
        div.appendChild(contrTop)
        contrTop.style.display = 'flex'
        contrTop.style.flexDirection = 'row'
        contrTop.style.justifyContent = 'center'
        contrTop.style.alignItems = 'center'
        contrTop.style.borderBottom = '1px solid grey'
        contrTop.style.paddingBottom = '10px'

        let textName = document.createElement('span')
        contrTop.appendChild(textName)
        textName.style.marginRight = '10px'
        textName.style.marginLeft = '10px'
        textName.innerText = that.userInfo.NickName
        // textName.appendChild(document.createTextNode('H003C'));

        let contrPosition = document.createElement('div')
        contrTop.appendChild(contrPosition)
        contrPosition.style.display = 'flex'
        contrPosition.style.flexDirection = 'row'
        contrPosition.style.justifyContent = 'center'
        contrPosition.style.alignItems = 'center'
        contrPosition.style.borderLeft = '1px solid gray'
        contrPosition.style.borderRight = '1px solid gray'
        contrPosition.style.paddingLeft = '10px'
        contrPosition.style.paddingRight = '10px'
        contrPosition.addEventListener("click", function () {
          if (that.userInfo.IsShared) {
            Toast({
              message: "非主控号无此权限",
              duration: 3000
            })
          } else {
            that.sendCommand2Device()
          }
        });

        let imgPosition = document.createElement('img')
        contrPosition.appendChild(imgPosition)
        imgPosition.src = iconPosition
        imgPosition.style.width = 20 + "px"
        imgPosition.style.height = 20 + "px"


        let textPosition = document.createElement('span')
        contrPosition.appendChild(textPosition)
        textPosition.appendChild(document.createTextNode('当前位置'));

        let contrNavigation = document.createElement('div')
        contrTop.appendChild(contrNavigation)
        contrNavigation.style.display = 'flex'
        contrNavigation.style.flexDirection = 'row'
        contrNavigation.style.justifyContent = 'center'
        contrNavigation.style.alignItems = 'center'
        contrNavigation.style.marginRight = '10px'
        contrNavigation.style.marginLeft = '10px'
        contrNavigation.addEventListener("click", function () {
          window.location.href = "http://api.map.baidu.com/marker?location=" + that.center.lat + "," + that.center.lng + "&title=" + localStorage.Address + "&output=html"
        });

        let imgNavigation = document.createElement('img')
        contrNavigation.appendChild(imgNavigation)
        imgNavigation.src = iconNavigation
        imgNavigation.style.width = 20 + "px"
        imgNavigation.style.height = 20 + "px"

        let textNavigation = document.createElement('span')
        contrNavigation.appendChild(textNavigation)
        textNavigation.appendChild(document.createTextNode('导航'));

        // 中： 时间、定位方式
        let contrMid = document.createElement('div')
        div.appendChild(contrMid)
        contrMid.style.display = 'flex'
        contrMid.style.flexDirection = 'row'
        contrMid.style.justifyContent = 'center'
        contrMid.style.alignItems = 'center'
        contrMid.style.marginTop = '10px'
        contrMid.style.marginBottom = '10px'

        let contrTime = document.createElement('div')
        contrMid.appendChild(contrTime)
        contrTime.style.display = 'flex'
        contrTime.style.flexDirection = 'row'
        contrTime.style.justifyContent = 'center'
        contrTime.style.alignItems = 'center'
        contrTime.style.marginRight = '10px'
        contrTime.style.marginLeft = '10px'

        let imgTime = document.createElement('img')
        contrTime.appendChild(imgTime)
        imgTime.src = iconTime
        imgTime.style.width = 20 + "px"
        imgTime.style.height = 20 + "px"
        imgTime.style.marginRight = "5px"

        let textTime = document.createElement('span')
        contrTime.appendChild(textTime)
        textTime.style.fontSize = "12px";
        textTime.innerText = utc2localStr(str2Date(that.userInfo.DeviceUtcTime))
        // textTime.appendChild(document.createTextNode('2020-03-35 14:00'));

        let contrLocation = document.createElement('div')
        contrMid.appendChild(contrLocation)
        contrLocation.style.display = 'flex'
        contrLocation.style.flexDirection = 'row'
        contrLocation.style.justifyContent = 'center'
        contrLocation.style.alignItems = 'center'
        contrLocation.style.paddingLeft = '10px'
        contrLocation.style.paddingRight = '10px'

        let imgLocation = document.createElement('img')
        contrLocation.appendChild(imgLocation)
        imgLocation.src = iconType
        imgLocation.style.width = 20 + "px"
        imgLocation.style.height = 20 + "px"
        imgLocation.style.marginRight = "5px"


        let textLocation = document.createElement('span')
        contrLocation.appendChild(textLocation)
        textLocation.style.fontSize = "12px";
        textLocation.innerText = that.userInfo.LocationType
        // textLocation.appendChild(document.createTextNode('WIFI定位'));

        // 下： 地址
        let textAddress = document.createElement('span')
        div.appendChild(textAddress)
        textAddress.style.fontSize = "12px";
        textAddress.style.margin = '0 10px 0 10px'
        textAddress.style.whiteSpace = "break-spaces";
        textAddress.innerText = localStorage.Address

        map.getPanes().labelPane.appendChild(div);

        ComplexCustomOverlay.prototype.addEventListener = function (event, fun) {
          div['on' + event] = fun;
        }

        return div;
      }

      ComplexCustomOverlay.prototype.draw = function () {
        var map = this._map;
        var pixel = map.pointToOverlayPixel(this._point);
        this._div.style.left = pixel.x - this._div.offsetWidth / 2 + "px";
        this._div.style.top = pixel.y - this._div.offsetHeight - 40 + "px";
        // console.log(this._div.offsetHeight)
        // console.log(this._div.offsetWidth)
      }

      this.infoOverlay = new ComplexCustomOverlay(point, 'item.name', 'tex');
      this.infoOverlay.addEventListener('click', function () {
        alert('click');
      });
      this.map.addOverlay(this.infoOverlay);
      // test end


      // var html =
      //     "<div>" +
      //       "<div>" +
      //         "<div><img/>H300C</div>" +
      //         "<div><img/>当前位置</div>" +
      //         "<div><img/>导航</div>" +
      //       "</div>" +
      //       "<div>" +
      //     "</div></div>";
      //
      // var opts = {
      //   width: 250,     // 信息窗口宽度
      //   height: 100,     // 信息窗口高度
      //   // title : "Hello"  // 信息窗口标题
      // }
      // var infoWindow = new BMap.InfoWindow(html, opts);  // 创建信息窗口对象
      // this.map.openInfoWindow(infoWindow, point);      // 打开信息窗口


      /* eslint-enable */
    }
  },
}
</script>

<style scoped>
.map {
  height: 100vh;
}

.contr-map {

}

.contr-button {
  position: absolute;
  top: 40%;
  left: 20px;
}

.location-type {
  width: 38px;
  display: block;
}

.contr-status {
  position: absolute;
  right: 10px;
  top: 8%;
  display: flex;
  flex-direction: column;
  background-color: #616060;
  border-radius: 5px;
  padding: 8px 8px 0 8px;
}

.status-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
}
</style>