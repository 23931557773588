<template>
  <div class="container">
      <!--  实时位置  -->
      <location-map ref="map" :center="center" :zoom="zoom" :userInfo="userInfo"/>
  </div>
</template>

<script>

import {Language, MapType} from "@/constants/contants";
import {post} from "@/router/httpUtils";
import LocationMap from "@/components/LocationMap";

export default {
  name: "Location",
  // eslint-disable-next-line vue/no-unused-components
  components: {LocationMap},
  data() {
    return {
      center: {lng: 0, lat: 0},
      zoom: 13,
      BMap: null,
      map: null,
      trackResponse: null,
      bdLocation: true,
      timer: null,
      userInfo: {}
    }
  },
  mounted() {
    this.getLocation()
    // if (this.timer) {
    //   clearInterval(this.timer)
    // } else {
    //   this.timer = setInterval(() => {
    //     this.getLocation()
    //   }, 10000)
    // }

  },
  destroyed() {
    // clearInterval(this.timer)
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    getLocation() {
      post('/api/Device/PersonTracking', {
        "DeviceId": localStorage.DeviceId,
        "Language": Language,
        "MapType": MapType,
        "Token": localStorage.Token,
        "UserId": localStorage.UserId
      })
          .then(response => {
            // handle success
            console.log(response);
            this.trackResponse = response
            // todo index用多少  
            const device = this.trackResponse.Items[0]
            console.log(device)
            this.center = {
              lng: device.Longitude,
              lat: device.Latitude
            }
            // this.$route.params.DeviceParams
            // todo 根据参数判断图标显示隐藏
            this.userInfo = {
              NickName: device.NickName,
              DeviceUtcTime: device.DeviceUtcTime,
              LocationType: this.getLocationType(device.PositionType),
              imgBattery: this.getImageBattery(device.Battery),
              imgSignal: this.getImageSignal(device.Signal),
              imgSatellite: this.getImageSatellite(device.Satellite),
              DeviceModel: device.TypeValue,
              IsShared: device.IsShared,
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
    },
    getLocationType(type) {
      let result;
      switch (type) {
        case 0:
          result = '未定位'
          break
        case 1:
          result = '卫星定位'
          break
        case 2:
          result = '基站定位'
          break
        case 3:
          result = 'WIFI定位'
          break
      }
      return result
    },
    getImageBattery(level) {
      let result;
      if (level == 0) {
        result = require('../assets/main_electricity_0.png')
      } else if (level > 0 && level <= 10) {
        result = require('../assets/main_electricity_1.png')
      } else if (level > 10 && level <= 20) {
        result = require('../assets/main_electricity_2.png')
      } else if (level > 20 && level <= 30) {
        result = require('../assets/main_electricity_3.png')
      } else if (level > 30 && level <= 40) {
        result = require('../assets/main_electricity_4.png')
      } else if (level > 40 && level <= 50) {
        result = require('../assets/main_electricity_5.png')
      } else if (level > 50 && level <= 60) {
        result = require('../assets/main_electricity_6.png')
      } else if (level > 60 && level <= 70) {
        result = require('../assets/main_electricity_7.png')
      } else if (level > 70 && level <= 80) {
        result = require('../assets/main_electricity_8.png')
      } else if (level > 80 && level <=90) {
        result = require('../assets/main_electricity_9.png')
      }else if (level > 90 && level <100) {
        result = require('../assets/main_electricity_10.png')
      } else if (level == 100) {
        result = require('../assets/main_electricity_11.png')
      } else {
        result = require('../assets/main_electricity_0.png')
      }
      return result
    },
    getImageSignal(level) {
      let result;
      if (level == 0) {
        result = require('../assets/main_signal_0.png')
      } else if (level > 0 && level <= 25) {
        result = require('../assets/main_signal_1.png')
      } else if (level > 25 && level <= 50) {
        result = require('../assets/main_signal_2.png')
      } else if (level > 50 && level <= 75) {
        result = require('../assets/main_signal_3.png')
      } else if (level > 75 && level <100) {
        result = require('../assets/main_signal_4.png')
      } else if (level == 100) {
        result = require('../assets/main_signal_5.png')
      } else {
        result = require('../assets/main_signal_0.png')
      }
      return result
    },
    getImageSatellite(level) {
      let result;
      if (level == 0) {
        result = require('../assets/main_satellite_0.png')
      } else if (level > 0 && level <= 4) {
        result = require('../assets/main_satellite_1.png')
      } else if (level > 4 && level <= 8) {
        result = require('../assets/main_satellite_2.png')
      } else if (level > 8 && level <= 12) {
        result = require('../assets/main_satellite_3.png')
      }else if (level > 12) {
        result = require('../assets/main_satellite_3.png')
      } else {
        result = require('../assets/main_satellite_0.png')
      }
      return result
    }
  }
}
</script>

<style scoped>
.container {
}
</style>